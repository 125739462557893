<template>
    <v-container>
        <v-progress-circular
            indeterminate
            class="pos-center"
            color="primary"
            v-if="isLoading.verify"
        ></v-progress-circular>
        <v-row v-else>
            <v-col class="text-center ma-auto" cols="12" sm="6">
                <v-card class="ma-auto card-top" v-if="isVerified">
                    <v-card-title class="justify-center">
                        Email Verification
                    </v-card-title>
                    <v-card-text class="text-center"
                        ><p class="text-body-1" v-text="message" />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" depressed :to="{ name: 'Login' }"
                            >Login</v-btn
                        >
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>

                <v-card class="ma-auto card-top" v-else>
                    <v-card-text>
                        <p class="text-body-1 mb-0">
                            I'm afraid we can't verify your account.
                        </p>
                        <p class="text-body-1">
                            Do you want to resend the verification to your
                            email?
                        </p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn :to="{ name: 'ResendVerification' }"
                            >Resend Verification Email</v-btn
                        >
                        <v-spacer></v-spacer>
                        <div>or</div>
                        <v-spacer></v-spacer>
                        <v-btn text :to="{ name: 'login' }">Login</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import api from "@/api";

export default {
    data() {
        return {
            isVerified: false,
            isLoading: {
                verify: false
            },
            message: ""
        };
    },
    created() {
        this.verifyToken();
    },

    methods: {
        async verifyToken() {
            try {
                this.isLoading.verify = true;
                const res = await api.auth.verifyUser(this.$route.params);
                this.message = res.data.message;
                this.isVerified = true;
            } catch (err) {
                this.isVerified = false;
            } finally {
                this.isLoading.verify = false;
            }
        }
    }
};
</script>
